import "../../styles/price.css";
import PriceCard from "./pricecards";

const Price = ({scrollToContact,activesection}) => {
    return (
        <>
            <div id="pricing" className="mt-8 px-4 sm:px-8 md:px-20 pt-10 pb-10 4k:px-[25%]   max-w-screen-4k mx-auto bg-gradient-to-b from-[#FFFFFF] via-[#FFFFFF] via-40% to-[#E9E6FD]" > {/* Updated padding for smaller screens */}
                <div className="flex justify-center items-start relative">
                    <div className="block">
                        <button className="tag-title font-[400] text-[16px]">Pricing</button>
                    </div>
                </div>
                <div className="mt-2 block">
                    <h2 className="text-center font-[500] text-[32px] leading-[63px] sm:text-[52px]"> {/* Responsive font size */}
                        Scalable Pricing That Adapts  to <br/> Your Business
                    </h2>
                    <p className="mt-2 text-center font-[400] text-[16px] sm:text-[18px] text-[#160F22]"> {/* Responsive font size */}
                        Choose a plan that grows with you, ensuring you only pay for what you need.
                    </p>
                </div>

                {/* <div className="mt-3 flex justify-center">
                    <div className="block">
                        <div className="flex bg-[#1F1929] bg-opacity-0 w-full sm:w-[207px] pl-1 pr-2 h-[39px] rounded-[40px] text-white justify-center items-center">
                            <button className="bg-[#7C50DD] rounded-[40px] w-[104px] flex justify-center items-center pr-[10px] pb-[8px] pl-[5px] pt-[8px] text-[10px] font-bold">Monthly</button>
                            <button className="hover:bg-[#7C50DD] rounded-[40px] w-[104px] flex justify-center items-center pr-[10px] pb-[8px] pl-[5px] pt-[8px] text-[10px] font-bold">Annual</button> 
                        </div>
                        <p className="text-center mt-2 text-[11px] font-bold text-linear-gradient">Save 20% Monthly</p>
                    </div>
                </div> */}
                <PriceCard scrolltocontact={scrollToContact}/>
            </div>
        </>
    );
}

export default Price;
